import axios from 'axios'
export default {
    handleCallback(code){
        return axios.get(`/api/salesforce/callback?code=${code}`);
    },
    checkAuth(){
        return axios.get(`/api/salesforce/check-auth`);
    },
    listObjects(surveyId){
        return axios.get(`/api/${surveyId}/salesforce/objects`);
    },
    listFields(surveyId, object){
        return axios.get(`/api/${surveyId}/salesforce/objects/${object}/fields`);
    }
}