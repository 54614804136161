<template>
    <connect-layout :fluid="false">
        <v-container v-if="!loadingDataMappingAction" style="max-width:1200px;">
            <v-breadcrumbs color="primary" class="px-0" :items="breadcrumbItems"></v-breadcrumbs>
            <div class="text-h6">Salesforce Custom Mapping</div>
            <div class="text">
                Map your survey questions to Salesforce fields
            </div>
            <v-divider class="my-4"></v-divider>
            <v-form ref="form">
                <template v-if="!showError">
                    <div class="mb-4">
                        <div class="mb-2">
                            Step 1: Select a Salesforce object that you want to pass
                            data into
                        </div>
                        <v-autocomplete
                            v-model="selectedObject"
                            :loading="loadingObjects"
                            :items="objects"
                            flat
                            hide-details
                            placeholder="Select an object"
                            item-text="label"
                            item-value="name"
                            outlined
                            return-object
                            clearable
                            dense
                            @change="handleObjectChange"
                        ></v-autocomplete>
                    </div>
                    <div
                        class="mb-4"
                        v-if="availableActions && availableActions.length"
                    >
                        <div class="mb-2">Step 2: Select an action</div>
                        <v-select
                            v-model="selectedAction"
                            :items="availableActions"
                            flat
                            hide-details
                            placeholder="Select an action"
                            item-text="label"
                            item-value="value"
                            outlined
                            clearable
                            dense
                        ></v-select>

                        <v-text-field
                            v-if="selectedAction==='update'"
                            v-model="lookupRecordKey"
                            outlined
                            dense
                            clearable
                            class="mt-2"
                            placeholder="Enter a lookup record key e.g. case_id"
                            hint="The field that will be used to find the record in Salesforce. Default is 'record_id'"
                            hide-details="auto"
                            :rules="lookupRecordRule"
                        ></v-text-field>
        
                        <v-card class="d-flex pa-3 mt-2" outlined v-if="selectedAction==='update'">
                            <v-icon class="mr-2">mdi-information-outline</v-icon>
                            <div>
                                Please make sure that when you share your survey, the link includes the <code>{{lookupRecordKey || 'record_id'}}</code> parameter. This is required for the action to find the record in Salesforce.
                                <i class="grey--text text--darken-1">E. g. https://app.voiceform.com/to/[survey_hash]/?<code class="primary lighten-5 primary--text text--darken-1 mx-1">{{lookupRecordKey || 'record_id'}}=a01Hs00001qH9v4</code></i>
                            </div>
                        </v-card>
                    </div>
                    <mapping-builder
                        v-if="selectedObject && selectedAction"
                        :selectedObject="selectedObject"
                        :selectedAction="selectedAction"
                        :questions="questions"
                        v-model="mappedItems"
                    ></mapping-builder>
                    <div>
                        <v-divider class="my-4"></v-divider>
                        <v-btn :disabled="updatingData || !(mappedItems && mappedItems.length)" class="mt-4 mr-2" elevation="0" color="primary" @click="handleSubmit">
                            {{isUpdateMode?'Update' :'Create new mapping'}}
                        </v-btn>
                        <v-btn :disabled="updatingData" class="mt-4" elevation="0" :to="{
                            name: 'dashboard.voiceform.connect.data-mapping',
                            params: {
                                surveyId: $route.params.surveyId
                            }
                        }">
                            Cancel
                        </v-btn>
                    </div>
                </template>
            </v-form>
        </v-container>
        <v-overlay :value="loadingDataMappingAction">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card outlined class="pa-3" v-if="showError">
            <v-icon color="error">mdi-alert-circle-outline</v-icon>
            Looks like something is wrong with your Salesforce connection. Please check your Salesforce connection settings and try again. If you are not the account owner, please contact your account owner.
        </v-card>
    </connect-layout>
</template>

<script>
import ConnectLayout from "../../../../../layouts/ConnectLayout.vue";
import SalesforceApi from "@/api/SalesforceApi";
import MappingBuilder from "./MappingBuilder.vue";
import BuilderApi from "@/api/BuilderApi";
import DataMappingApi from "@/api/DataMappingApi";
import { mapMutations } from 'vuex';

export default {
    components: {
        ConnectLayout,
        MappingBuilder
    },
    data() {
        return {
            loadingDataMappingAction: this.$route.params.actionId ? true : false,
            updatingData: false,
            selectedObject: null,
            loadingObjects: false,
            selectedAction: null,
            selectedRecordLookup: "id",
            objects: [],
            questions: [],
            showError: false,
            lookupRecordKey: null,
            mappedItems: [],
            lookupRecordRule: [
                    v => {
                        if (!v) return true;
                        return /^[a-zA-Z_]\w*$/.test(v) || 'Attribute key must start with a letter and contain only alphanumeric characters and underscores'
                    },
            ],
        };
    },
    provide() {
        return {
            refreshVariables: this.loadVariables,
        };
    },
    computed: {
        isUpdateMode(){
            return !!this.$route.params.actionId
        },
        availableActions() {
            const actions = [];
            if (!this.selectedObject) return actions;

            if (this.selectedObject.createable) {
                actions.push({ label: "Create Record", value: "create" });
            }
            if (this.selectedObject.updateable) {
                actions.push({ label: "Update Record", value: "update" });
            }
            // TODO: Upsert is not supported yet
            // if (
            //     this.selectedObject.createable &&
            //     this.selectedObject.updateable
            // ) {
            //     actions.push({
            //         label: "Upsert (update or create record if doesn't exist)",
            //         value: "upsert"
            //     });
            // }
            return actions;
        },
        breadcrumbItems(){
            const items = [
                {   
                    text: 'Data Mapping',
                    disabled: false,
                    exact: true,
                    to: {
                        name: 'dashboard.voiceform.connect.data-mapping'
                    }
                }
            ]

            items.push({
                text: `${this.$route.params.actionId?'Update':'Create'} Data Mapping Action`,
                to: {
                    name: this.$route.name,
                    params: this.$route.params
                }
            })

            return items
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async fetchObjects() {
            try {
                this.loadingObjects = true;
                const res = await SalesforceApi.listObjects(this.$route.params.surveyId);
                if (res && res.data && res.data.length > 0) {
                    this.objects = res.data;
                }
            } catch (error) {
                this.showSnackBar({
                    text: "Failed to fetch Salesforce objects",
                    color: "error",
                    timeout: 2000
                });
                this.showError = true
            }finally{
                this.loadingObjects = false;
            }
            
        },
        async fetchQuestions() {
            try {
                this.loadingQuestions = true;
                const res = await BuilderApi.listQuestions(
                    this.$route.params.surveyId
                );
                if (res && res.data && res.data.length > 0) {
                    this.questions = res.data;
                }
            } catch (error) {
                this.showSnackBar({
                    text: "Failed to fetch questions",
                    color: "error",
                    timeout: 2000
                });
            } finally {
                this.loadingQuestions = false;
            }
        },
        async fetchExitingAction() {
            try {
                this.loadingDataMappingAction = true;
                const res = await DataMappingApi.show(
                    this.$route.params.surveyId,
                    this.$route.params.actionId
                );
                this.selectedObject = this.objects.find(
                    obj => obj.name === res.data.sf_object_name
                );
                this.selectedAction = res.data.action;
                this.mappedItems = res.data.mapping_rules
                if(res.data.record_lookup_rules && res.data.record_lookup_rules.key){
                    this.lookupRecordKey = res.data.record_lookup_rules.key
                }
            } catch (error) {
                console.error(error);
                this.showSnackBar({
                    text: "Failed to fetch data mapping",
                    color: "error",
                    timeout: 2000
                });
            } finally {
                this.loadingDataMappingAction = false;
            }
        },
        handleObjectChange() {
            this.selectedAction = null;
            this.mappedItems = [];
        },
        async handleSubmit(){
            if(this.$refs.form.validate()){
                try {
                    
                    this.updatingData = true
                    const data = {
                        sf_object_name: this.selectedObject.name,
                        action: this.selectedAction,
                        mapping_rules: this.mappedItems.filter(item => item.salesforceField && (item.questionField || item.metadataAttributeKey))
                    }
                    if(this.lookupRecordKey){
                        data.record_lookup_rules = {
                            key: this.lookupRecordKey
                        }
                    }
                    if(this.isUpdateMode){
                        await DataMappingApi.update(this.$route.params.surveyId, this.$route.params.actionId, data)
                        this.showSnackBar({
                            text: 'Data mapping updated successfully', 
                            color: 'success', 
                            timeout: 2000
                        })
                        this.$router.push({
                            name: 'dashboard.voiceform.connect.data-mapping',
                            params: {
                                surveyId: this.$route.params.surveyId
                            }
                        })
                    }else{
                        const res = await DataMappingApi.create(this.$route.params.surveyId, data)
                        if(res && res.data){
                            this.showSnackBar({
                                text: 'Data mapping created successfully', 
                                color: 'success', 
                                timeout: 2000
                            })
                            this.$router.push({
                                name: 'dashboard.voiceform.connect.data-mapping',
                                params: {
                                    surveyId: this.$route.params.surveyId
                                }
                            })
                        }
                    }
                } catch (error) {
                    console.log(error)
                    this.updatingData = false
                    this.showSnackBar({
                        text: `Failed to ${this.isUpdateMode?'update':'create'} data mapping`, 
                        color: 'error', 
                        timeout: 2000
                    })
                }

            }else{
                this.showSnackBar({
                    text: 'Some fields are invalid', 
                    color: 'error', 
                    timeout: 2000
                })
            }
        }
    },
    async mounted() {
        this.fetchQuestions();
        await this.fetchObjects();
        if (this.$route.params.actionId) {
            this.fetchExitingAction();
        }
    }
};
</script>
