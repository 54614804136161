<template>
    <div class="pa-5 d-flex align-centre justify-center" v-if="loadingFields">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
    </div>
    <div v-else>
        <div class="d-flex">
            <div class="text-h6 mb-4">Build your mapping</div>
            <v-spacer></v-spacer>
            <v-btn text color="primary" elevation="0" @click="addNewMappingItem">
                <v-icon left>mdi-plus</v-icon>
                Add new mapping item
            </v-btn>
        </div>
        <v-row>
            <v-col cols="12" md="7">
                <div class="text-caption grey--text text--darken-1">
                    Voiceform question/metadata field
                </div>
            </v-col>
            <v-col cols="12" md="4">
                <div class="text-caption grey--text text--darken-1">
                    Salesforce Fields
                </div>
            </v-col>
        </v-row>
            <mapping-builder-item
                v-for="(item, index) in mappedItems"
                :key="`mapping-item-${selectedObject.name}-${index}`"
                :objectFields="objectFields"
                :questions="questions"
                :value="item"
                @input="updateItem(index, $event)"
                @delete="deleteMappingItem(index)"
            >
            </mapping-builder-item>
    </div>
</template>

<script>
import SalesforceApi from "@/api/SalesforceApi";
import MappingBuilderItem from "./MappingBuilderItem.vue";
import { mapMutations } from 'vuex';
export default {
    components: { MappingBuilderItem },
    props: {
        selectedObject: {
            required: true
        },
        selectedAction: {
            required: true
        },
        questions: {
            required: true
        },
        value: {
            required: false,
        }
    },
    data() {
        return {
            loadingFields: false,
            objectFields: [],
            mappedItems: [],
            updatingData: false
        };
    },
    computed: {
        isUpdateMode(){
            return !!this.$route.params.actionId
        },
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        updateItem(index, newItem) {
            this.$set(this.mappedItems, index, newItem);
            this.$emit("input", this.mappedItems);
        },
        async fetchFields() {
            this.loadingFields = true;
            try {
                const { data } = await SalesforceApi.listFields(
                    this.$route.params.surveyId,
                    this.selectedObject.name
                );
                this.objectFields = data;
                if (
                    this.mappedItems.length === 0 &&
                    this.selectedAction === "create"
                ) {
                    this.mappedItems = this.objectFields.map(field => {
                        return {
                            dataType: "field",
                            questionField: null,
                            metadataAttributeKey: null,
                            salesforceField: field
                        };
                    });
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.loadingFields = false;
                this.$emit("input", this.mappedItems);
            }
        },
        addNewMappingItem() {
            const newMappingItem = {
                dataType: "field",
                questionField: null,
                metadataAttributeKey: null,
                salesforceField: null,
                valueType: null
            };
            this.mappedItems.push(newMappingItem);
            this.$emit("input", this.mappedItems);
        },
        deleteMappingItem(index) {
            this.mappedItems.splice(index, 1);
            this.$emit("input", this.mappedItems);
        },
    },
    watch: {
        ['selectedObject.name']: {
            handler: async function () {
                this.mappedItems = [];
                this.fetchFields();
            },
            immediate: true
        }
    },
    mounted(){
        if(this.value){
            this.mappedItems = this.value
        }
    }
};
</script>
