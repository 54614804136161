import axios from 'axios'


export default {
    list(surveyId) {
        const url = `/api/builder/${surveyId}/data-mapping-actions`;
        return axios.get(url);
    },

    show(surveyId, actionId) {
        const url = `/api/builder/${surveyId}/data-mapping-actions/${actionId}`;
        return axios.get(url);
    },

    create(surveyId, data) {
        const url = `/api/builder/${surveyId}/data-mapping-actions`;
        return axios.post(url, data);
    },

    update(surveyId, actionId, data) {
        const url = `/api/builder/${surveyId}/data-mapping-actions/${actionId}`;
        return axios.put(url, data);
    },

    delete(surveyId, actionId) {
        const url = `/api/builder/${surveyId}/data-mapping-actions/${actionId}`;
        return axios.delete(url);
    },

    updateActionOrder(surveyId, actionId, data) {
        const url = `/api/builder/${surveyId}/data-mapping-actions/${actionId}/order`;
        return axios.post(url, data);
    },
};
