<template>
    <v-row class="d-flex align-start">
        <v-col class="d-flex align-start" cols="12" sm="7">
            <v-select
                v-model="dataType"
                :items="[{text: 'Question field', value:'field'}, {text: 'Metadata attribute',value:'metadata'}]"
                flat
                hide-details="auto"
                placeholder="Select data type"
                outlined
                dense
                style="max-width: 172px"
                class="mr-2"
                :rules="rules.dataType"
            >
                <template v-slot:selection="data">
                        <div class="text-caption trimmed-option">{{data.item.text}}</div>
                    </template>
                    <template v-slot:item="data">
                        <div class="text-caption trimmed-option">{{data.item.text}}</div>
                    </template>
                </v-select>
            <v-autocomplete
                v-if="['field'].includes(dataType)"
                v-model="selectedQuestionField"
                :items="filteredQuestions"
                flat
                hide-details="auto"
                placeholder="Select a field"
                :item-text="questionFieldText"
                item-value="id"
                outlined
                return-object
                clearable
                dense
                single-line
                :rules="rules.questionField"
                style="max-width: 300px"
            >
                <template v-slot:selection="data">
                    <div style="width: 100%">
                        <question-item v-if="data.item" :question="data.item"></question-item>
                    </div>
                </template>
                <template v-slot:item="data">
                    <div style="width: 100%">
                        <question-item v-if="data.item" :question="data.item"></question-item>
                    </div>
                </template>
            </v-autocomplete>
            <v-text-field
                v-else-if="dataType === 'metadata'"
                v-model="metadataAttributeKey"
                flat
                hide-details="auto"
                placeholder="Enter metadata attribute key"
                outlined
                clearable
                dense
                :rules="rules.metadataAttribute"
                @input="handleAttributeKeyInput"
                class="variable-input"
                :messages="metadataAttributeMessages"
            >
            </v-text-field>
            <v-select
                v-if="availableValueOptions && availableValueOptions.length"
                v-model="selectedValueType"
                :items="availableValueOptions"
                flat
                hide-details="auto"
                placeholder="Select value option"
                outlined
                dense
                style="max-width: 172px"
                class="ml-2"
                :rules="rules.valueType"
            >
                <template v-slot:selection="data">
                    <div class="text-caption trimmed-option">{{data.item.text}}</div>
                </template>
                <template v-slot:item="data">
                    <div class="text-caption trimmed-option">{{data.item.text}}</div>
                </template>
            </v-select>
            <v-icon class="mx-2 mt-2">mdi-arrow-right-thin</v-icon>
        </v-col>
        <v-col cols="12" sm="4">
            <v-autocomplete
                v-model="selectedSalesForceField"
                :items="objectFields"
                flat
                hide-details="auto"
                placeholder="Select salesforce field"
                item-text="label"
                item-value="name"
                outlined
                return-object
                clearable
                dense
                :rules="rules.salesforceField"
            >
                <template v-slot:selection="data">
                    <div>
                        <provider-field-item :field="data.item"></provider-field-item>
                    </div>
                </template>
                <template v-slot:item="data">
                    <div>
                        <provider-field-item :field="data.item"></provider-field-item>
                    </div>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="auto" class="d-flex align-center justify-end">
            <v-btn @click="$emit('delete')" icon>
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import QuestionItem from '@/views/dashboard/voiceform/create/logic/QuestionItem.vue';
import ProviderFieldItem from './ProviderFieldItem.vue';
import { VOICE_RESPONSE,  VOICE_QUESTION, DESCRIPTION_SLIDE, FILE_UPLOAD} from '@/configs/questionTypes';
export default {
    props:{
        objectFields: {
            type: Array,
            required: true,
        },
        questions: {
            type: Array,
            required: true,
        },
        value: {
            type: Object,
            required: true,
        },
    },
    components: {
        QuestionItem,
        ProviderFieldItem,
    },
    methods: {
        questionFieldText(question) {
            return question.text || question.type;
        },
        formatFieldLabel(field) {
            return !field['nillable'] && !field['defaultedOnCreate'] ? `${field.label} *` : field.label;
        },
        handleAttributeKeyInput(val){
            this.metadataAttributeKey = val.replace(' ', '_').toLowerCase().slice(0, 35)
        }
    },
    computed: {
        filteredQuestions(){
            return this.questions.filter(q => ![FILE_UPLOAD, DESCRIPTION_SLIDE].includes(q.type))
        },
        availableValueOptions(){
            if(this.selectedQuestionField && [VOICE_QUESTION, VOICE_RESPONSE].includes(this.selectedQuestionField.type)){
                return [
                    {text: 'Transcript', value: 'transcript'},
                    {text: 'Sentiment Label', value: 'sentiment_label'},
                    {text: 'Sentiment Score', value: 'sentiment_score'},
                    {text: 'Recording URL', value: 'recording_url'},
                ]
            }
            return []
        },
        selectedValueType: {
            get(){
                return this.value.valueType || 'transcript'
            },
            set(val){
                this.$emit('input', {
                    ...this.value,
                    valueType: val || null
                })
            }
        },
        dataType:{
            get(){
                return this.value.dataType || 'field'
            },
            set(val){
                this.selectedQuestionField = null
                this.metadataAttributeKey = null
                this.selectedValueType = null

                this.$emit('input', {
                    ...this.value,
                    dataType: val || null
                })
            }
        },
        selectedQuestionField: {
            get(){
                return this.value.questionField || null
            },
            set(val){
                this.$emit('input', {
                    ...this.value,
                    questionField: val || null
                })
            }
        },
        metadataAttributeKey: {
            get(){
                return this.value.metadataAttributeKey || ''
            },
            set(val){
                this.$emit('input', {
                    ...this.value,
                    metadataAttributeKey: val || null
                })
            }
        },
        selectedSalesForceField: {
            get(){
                return this.value.salesforceField || null
            },
            set(val){
                this.$emit('input', {
                    ...this.value,
                    salesforceField: val || null
                })
            }
        },
        metadataAttributeMessages(){
            // check if the field is valid if not return empty array
            if(!this.metadataAttributeKey || this.dataType !== 'metadata' || !this.selectedSalesForceField || this.selectedSalesForceField['nillable'] || this.selectedSalesForceField['defaultedOnCreate']){
                return []
            }
            return [`Passing e.g. https://app.voiceform.com/to/XX?${this.metadataAttributeKey}=value`]
        },
    },
    data(){
        return {
            rules: {
                dataType: [v => !!v || 'Data type is required'],
                valueType: [v => !!v || 'Value option is required'],
                questionField: [v => {
                    if(this.dataType === 'field' && this.selectedSalesForceField && !this.selectedSalesForceField['nillable'] && !this.selectedSalesForceField['defaultedOnCreate']){
                        return !!v || 'Question field is required'
                    }
                    return true
                }],
                metadataAttribute: [
                    v => {
                        if(this.dataType === 'metadata' && this.selectedSalesForceField && !this.selectedSalesForceField['nillable'] && !this.selectedSalesForceField['defaultedOnCreate']){
                            return !!v || 'Metadata attribute is required'
                        }
                        return true
                    },
                    v => {
                        if (!v) return true;
                        return /^[a-zA-Z_]\w*$/.test(v) || 'Attribute key must start with a letter and contain only alphanumeric characters and underscores'
                    },
                ],
                salesforceField: [v => !!v || 'Salesforce field is required']
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.variable-input{
    font-family: 'Roboto Mono', monospace;
}
.trimmed-option{
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}
</style>