<template>
    <div class="text-body-2">
        <v-chip class="text-caption mr-1" color="deepOrange accent-1" dark v-if="!field['nillable'] && !field['defaultedOnCreate']" small label>Required</v-chip>
        <v-chip class="text-caption mr-1" color="info" small label>{{field['type']}}</v-chip>
        {{field.label}}
    </div>
</template>

<script>
export default {
    props: {
        field: {
            required: true,
        },
    }
}
</script>