<template>
    <form-builder :fluid="fluid">
        <v-app-bar
            :style="`top: ${this.$vuetify.application.top}px;`"
            height="48"
            fixed
            color="primary lighten-5 header px-0 py-0"
            elevation="0"
        >
            <div class="d-flex flex-grow-1">
                <div class="d-flex justify-flex-start align-center flex-grow-1">
                    <v-tabs
                        v-if="tabs"
                        background-color="transparent"
                        color="primary"
                    >
                        <v-tab
                            v-for="tab in tabs"
                            class="text-subtitle-2 tab"
                            :key="tab.text"
                            :to="tab.to"
                        >
                            {{ tab.text }}
                        </v-tab>
                    </v-tabs>
                </div>
            </div>
        </v-app-bar>
        <div class="pt-12"></div>
        <slot></slot>
    </form-builder>
</template>

<script>
import { mapGetters } from "vuex";
import FormBuilder from "./FormBuilder.vue";

export default {
    props: {
        fluid: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            loadingExport: false
        }
    },
    computed: {
        ...mapGetters({
            features: "auth/features"
        }),
        enableExports() {
            return !!this.features && this.features.data_export;
        },
        hasDataMapping() {
            return !!this.features && this.features.data_mapping;
        },
        tabs() {
            return [
                {
                    text: "Integrations",
                    to: {
                        name: "dashboard.voiceform.connect.integrations",
                        params: {
                            surveyId: this.$route.params.surveyId
                        }
                    }
                },
                {
                    text: "Webhooks",
                    to: {
                        name: "dashboard.voiceform.connect.webhooks",
                        params: {
                            surveyId: this.$route.params.surveyId
                        }
                    }
                },

                ...(this.hasDataMapping
                    ? [
                          {
                              text: "Data Mapping",
                              to: {
                                  name:
                                      "dashboard.voiceform.connect.data-mapping",
                                  params: {
                                      surveyId: this.$route.params.surveyId
                                  }
                              }
                          }
                      ]
                    : [])
            ];
        }
    },
    components: { FormBuilder }
};
</script>

<style scoped>
.tab {
    text-transform: capitalize;
}
.header {
    align-items: flex-start;
    /* top: 64px; */
}
</style>